export const routes = [
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/app/app/frontend/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": "blank"
    }
  },
  {
    path: '/ab_tests',
    /* internal name: '/ab_tests' */
    /* no component */
    children: [
      {
        path: '',
        name: '/ab_tests/',
        component: () => import('/app/app/frontend/pages/ab_tests/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "AbTest"
        }
      },
      {
        path: ':id',
        /* internal name: '/ab_tests/[id]' */
        /* no component */
        children: [
          {
            path: 'edit',
            name: '/ab_tests/[id]/edit',
            component: () => import('/app/app/frontend/pages/ab_tests/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "AbTest"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/ab_tests/new',
        component: () => import('/app/app/frontend/pages/ab_tests/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "AbTest"
        }
      }
    ],
  },
  {
    path: '/custom_pages',
    /* internal name: '/custom_pages' */
    /* no component */
    children: [
      {
        path: ':id',
        /* internal name: '/custom_pages/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/custom_pages/[id]/',
            component: () => import('/app/app/frontend/pages/custom_pages/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "CustomPage"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/custom_services',
    /* internal name: '/custom_services' */
    /* no component */
    children: [
      {
        path: '',
        name: '/custom_services/',
        component: () => import('/app/app/frontend/pages/custom_services/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "CustomService"
        }
      },
      {
        path: ':id',
        /* internal name: '/custom_services/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/custom_services/[id]/',
            component: () => import('/app/app/frontend/pages/custom_services/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "CustomService"
            }
          },
          {
            path: 'edit',
            name: '/custom_services/[id]/edit',
            component: () => import('/app/app/frontend/pages/custom_services/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "CustomService"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/custom_services/new',
        component: () => import('/app/app/frontend/pages/custom_services/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "CustomService"
        }
      }
    ],
  },
  {
    path: '/customisations',
    /* internal name: '/customisations' */
    /* no component */
    children: [
      {
        path: '',
        name: '/customisations/',
        component: () => import('/app/app/frontend/pages/customisations/index.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "Customisation2"
        }
      },
      {
        path: ':id',
        /* internal name: '/customisations/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/customisations/[id]/',
            component: () => import('/app/app/frontend/pages/customisations/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "Customisation2"
            }
          },
          {
            path: 'edit',
            name: '/customisations/[id]/edit',
            component: () => import('/app/app/frontend/pages/customisations/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "Customisation2"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/customisations/new',
        component: () => import('/app/app/frontend/pages/customisations/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "Customisation2"
        }
      }
    ],
  },
  {
    path: '/dashboard',
    /* internal name: '/dashboard' */
    /* no component */
    children: [
      {
        path: '',
        name: '/dashboard/',
        component: () => import('/app/app/frontend/pages/dashboard/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "dashboard",
          "transmitDateRange": true
        }
      }
    ],
  },
  {
    path: '/facebook_apps',
    /* internal name: '/facebook_apps' */
    /* no component */
    children: [
      {
        path: '',
        name: '/facebook_apps/',
        component: () => import('/app/app/frontend/pages/facebook_apps/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "FacebookApp"
        }
      },
      {
        path: ':id',
        /* internal name: '/facebook_apps/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/facebook_apps/[id]/',
            component: () => import('/app/app/frontend/pages/facebook_apps/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "FacebookApp"
            }
          },
          {
            path: 'edit',
            name: '/facebook_apps/[id]/edit',
            component: () => import('/app/app/frontend/pages/facebook_apps/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "edit",
              "subject": "FacebookApp"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/facebook_apps/new',
        component: () => import('/app/app/frontend/pages/facebook_apps/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "FacebookApp"
        }
      }
    ],
  },
  {
    path: '/forgot-password',
    name: '/forgot-password',
    component: () => import('/app/app/frontend/pages/forgot-password.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth",
      "redirectIfLoggedIn": true
    }
  },
  {
    path: '/group_api_keys',
    /* internal name: '/group_api_keys' */
    /* no component */
    children: [
      {
        path: '',
        name: '/group_api_keys/',
        component: () => import('/app/app/frontend/pages/group_api_keys/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "GroupAPIKey"
        }
      },
      {
        path: ':id',
        /* internal name: '/group_api_keys/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/group_api_keys/[id]/',
            component: () => import('/app/app/frontend/pages/group_api_keys/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "GroupAPIKey"
            }
          },
          {
            path: 'edit',
            name: '/group_api_keys/[id]/edit',
            component: () => import('/app/app/frontend/pages/group_api_keys/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "GroupAPIKey"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/group_api_keys/new',
        component: () => import('/app/app/frontend/pages/group_api_keys/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "GroupAPIKey"
        }
      }
    ],
  },
  {
    path: '/groups',
    /* internal name: '/groups' */
    /* no component */
    children: [
      {
        path: '',
        name: '/groups/',
        component: () => import('/app/app/frontend/pages/groups/index.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "Group"
        }
      },
      {
        path: ':id',
        /* internal name: '/groups/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/groups/[id]/',
            component: () => import('/app/app/frontend/pages/groups/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "Group"
            }
          },
          {
            path: 'edit',
            name: '/groups/[id]/edit',
            component: () => import('/app/app/frontend/pages/groups/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "edit",
              "subject": "Brand"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/import_trackers',
    /* internal name: '/import_trackers' */
    /* no component */
    children: [
      {
        path: '',
        name: '/import_trackers/',
        component: () => import('/app/app/frontend/pages/import_trackers/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "ImportTracker"
        }
      },
      {
        path: ':id',
        /* internal name: '/import_trackers/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/import_trackers/[id]/',
            component: () => import('/app/app/frontend/pages/import_trackers/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "ImportTracker"
            }
          },
          {
            path: 'images',
            name: '/import_trackers/[id]/images',
            component: () => import('/app/app/frontend/pages/import_trackers/[id]/images.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "ImportTracker"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/import_trackers/new',
        component: () => import('/app/app/frontend/pages/import_trackers/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "ImportTracker"
        }
      }
    ],
  },
  {
    path: '/landing_pages',
    /* internal name: '/landing_pages' */
    /* no component */
    children: [
      {
        path: '',
        name: '/landing_pages/',
        component: () => import('/app/app/frontend/pages/landing_pages/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "LandingPage"
        }
      },
      {
        path: ':id',
        /* internal name: '/landing_pages/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/landing_pages/[id]/',
            component: () => import('/app/app/frontend/pages/landing_pages/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'edit',
            name: '/landing_pages/[id]/edit',
            component: () => import('/app/app/frontend/pages/landing_pages/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "LandingPage"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/landing_pages/new',
        component: () => import('/app/app/frontend/pages/landing_pages/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "LandingPage"
        }
      }
    ],
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/app/app/frontend/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth",
      "redirectIfLoggedIn": true
    }
  },
  {
    path: '/michelin_import_msrp',
    /* internal name: '/michelin_import_msrp' */
    /* no component */
    children: [
      {
        path: '',
        name: '/michelin_import_msrp/',
        component: () => import('/app/app/frontend/pages/michelin_import_msrp/index.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "MichelinImportMsrp"
        }
      }
    ],
  },
  {
    path: '/michelin_offers',
    /* internal name: '/michelin_offers' */
    /* no component */
    children: [
      {
        path: '',
        name: '/michelin_offers/',
        component: () => import('/app/app/frontend/pages/michelin_offers/index.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "MichelinOffer"
        }
      },
      {
        path: ':id',
        /* internal name: '/michelin_offers/[id]' */
        /* no component */
        children: [
          {
            path: 'edit',
            name: '/michelin_offers/[id]/edit',
            component: () => import('/app/app/frontend/pages/michelin_offers/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "MichelinOffer"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/michelin_offers/new',
        component: () => import('/app/app/frontend/pages/michelin_offers/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "MichelinOffer"
        }
      }
    ],
  },
  {
    path: '/michelin_widget_customisations',
    /* internal name: '/michelin_widget_customisations' */
    /* no component */
    children: [
      {
        path: '',
        name: '/michelin_widget_customisations/',
        component: () => import('/app/app/frontend/pages/michelin_widget_customisations/index.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "MichelinWidgetCustomisation"
        }
      },
      {
        path: ':id',
        /* internal name: '/michelin_widget_customisations/[id]' */
        /* no component */
        children: [
          {
            path: 'edit',
            name: '/michelin_widget_customisations/[id]/edit',
            component: () => import('/app/app/frontend/pages/michelin_widget_customisations/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "manage",
              "subject": "MichelinWidgetCustomisation"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/michelin_widget_customisations/new',
        component: () => import('/app/app/frontend/pages/michelin_widget_customisations/new.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "MichelinWidgetCustomisation"
        }
      }
    ],
  },
  {
    path: '/multiproduct_landing_pages',
    /* internal name: '/multiproduct_landing_pages' */
    /* no component */
    children: [
      {
        path: '',
        name: '/multiproduct_landing_pages/',
        component: () => import('/app/app/frontend/pages/multiproduct_landing_pages/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "MultiproductLandingPage"
        }
      },
      {
        path: ':id',
        /* internal name: '/multiproduct_landing_pages/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/multiproduct_landing_pages/[id]/',
            component: () => import('/app/app/frontend/pages/multiproduct_landing_pages/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'edit',
            name: '/multiproduct_landing_pages/[id]/edit',
            component: () => import('/app/app/frontend/pages/multiproduct_landing_pages/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "MultiproductLandingPage"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/multiproduct_landing_pages/new',
        component: () => import('/app/app/frontend/pages/multiproduct_landing_pages/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "MultiproductLandingPage"
        }
      }
    ],
  },
  {
    path: '/multiproduct_widget_customisations',
    /* internal name: '/multiproduct_widget_customisations' */
    /* no component */
    children: [
      {
        path: '',
        name: '/multiproduct_widget_customisations/',
        component: () => import('/app/app/frontend/pages/multiproduct_widget_customisations/index.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "MultiproductWidgetCustomisation"
        }
      },
      {
        path: ':id',
        /* internal name: '/multiproduct_widget_customisations/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/multiproduct_widget_customisations/[id]/',
            component: () => import('/app/app/frontend/pages/multiproduct_widget_customisations/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "MultiproductWidgetCustomisation"
            }
          },
          {
            path: 'edit',
            name: '/multiproduct_widget_customisations/[id]/edit',
            component: () => import('/app/app/frontend/pages/multiproduct_widget_customisations/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "MultiproductWidgetCustomisation"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/multiproduct_widget_customisations/new',
        component: () => import('/app/app/frontend/pages/multiproduct_widget_customisations/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "MultiproductWidgetCustomisation"
        }
      }
    ],
  },
  {
    path: '/multiproduct_widgets',
    /* internal name: '/multiproduct_widgets' */
    /* no component */
    children: [
      {
        path: '',
        name: '/multiproduct_widgets/',
        component: () => import('/app/app/frontend/pages/multiproduct_widgets/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "MultiproductWidget"
        }
      },
      {
        path: ':id',
        /* internal name: '/multiproduct_widgets/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/multiproduct_widgets/[id]/',
            component: () => import('/app/app/frontend/pages/multiproduct_widgets/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'edit',
            name: '/multiproduct_widgets/[id]/edit',
            component: () => import('/app/app/frontend/pages/multiproduct_widgets/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "MultiproductWidget"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/multiproduct_widgets/new',
        component: () => import('/app/app/frontend/pages/multiproduct_widgets/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "MultiproductWidget"
        }
      }
    ],
  },
  {
    path: '/not-authorized',
    name: '/not-authorized',
    component: () => import('/app/app/frontend/pages/not-authorized.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth"
    }
  },
  {
    path: '/one_clicks',
    /* internal name: '/one_clicks' */
    /* no component */
    children: [
      {
        path: '',
        name: '/one_clicks/',
        component: () => import('/app/app/frontend/pages/one_clicks/index.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "OneClick"
        }
      },
      {
        path: ':id',
        /* internal name: '/one_clicks/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/one_clicks/[id]/',
            component: () => import('/app/app/frontend/pages/one_clicks/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "manage",
              "subject": "OneClick"
            }
          },
          {
            path: 'edit',
            name: '/one_clicks/[id]/edit',
            component: () => import('/app/app/frontend/pages/one_clicks/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "manage",
              "subject": "OneClick"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/one_clicks/new',
        component: () => import('/app/app/frontend/pages/one_clicks/new.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "OneClick"
        }
      }
    ],
  },
  {
    path: '/pim_data_sources',
    /* internal name: '/pim_data_sources' */
    /* no component */
    children: [
      {
        path: '',
        name: '/pim_data_sources/',
        component: () => import('/app/app/frontend/pages/pim_data_sources/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "PimDataSource"
        }
      },
      {
        path: ':id',
        /* internal name: '/pim_data_sources/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/pim_data_sources/[id]/',
            component: () => import('/app/app/frontend/pages/pim_data_sources/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "PimDataSource"
            }
          },
          {
            path: 'edit',
            name: '/pim_data_sources/[id]/edit',
            component: () => import('/app/app/frontend/pages/pim_data_sources/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "PimDataSource"
            }
          },
          {
            path: 'job',
            /* internal name: '/pim_data_sources/[id]/job' */
            /* no component */
            children: [
              {
                path: ':jobId',
                /* internal name: '/pim_data_sources/[id]/job/[jobId]' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/pim_data_sources/[id]/job/[jobId]/',
                    component: () => import('/app/app/frontend/pages/pim_data_sources/[id]/job/[jobId]/index.vue'),
                    /* no children */
                    meta: {
                      "action": "show",
                      "subject": "PimJob"
                    }
                  }
                ],
              }
            ],
          },
          {
            path: 'unknown_groups',
            name: '/pim_data_sources/[id]/unknown_groups',
            component: () => import('/app/app/frontend/pages/pim_data_sources/[id]/unknown_groups.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "PimDataSource"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/popin_implementations',
    /* internal name: '/popin_implementations' */
    /* no component */
    children: [
      {
        path: '',
        name: '/popin_implementations/',
        component: () => import('/app/app/frontend/pages/popin_implementations/index.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "PopinImplementation"
        }
      },
      {
        path: ':id',
        /* internal name: '/popin_implementations/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/popin_implementations/[id]/',
            component: () => import('/app/app/frontend/pages/popin_implementations/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "PopinImplementation"
            }
          },
          {
            path: 'edit',
            name: '/popin_implementations/[id]/edit',
            component: () => import('/app/app/frontend/pages/popin_implementations/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "PopinImplementation"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/popin_implementations/new',
        component: () => import('/app/app/frontend/pages/popin_implementations/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "PopinImplementation"
        }
      }
    ],
  },
  {
    path: '/product_filters',
    /* internal name: '/product_filters' */
    /* no component */
    children: [
      {
        path: '',
        name: '/product_filters/',
        component: () => import('/app/app/frontend/pages/product_filters/index.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "ProductFilter"
        }
      },
      {
        path: ':id',
        /* internal name: '/product_filters/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/product_filters/[id]/',
            component: () => import('/app/app/frontend/pages/product_filters/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "ProductFilter"
            }
          },
          {
            path: 'edit',
            name: '/product_filters/[id]/edit',
            component: () => import('/app/app/frontend/pages/product_filters/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "ProductFilter"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/product_filters/new',
        component: () => import('/app/app/frontend/pages/product_filters/new.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "ProductFilter"
        }
      }
    ],
  },
  {
    path: '/products',
    /* internal name: '/products' */
    /* no component */
    children: [
      {
        path: '',
        name: '/products/',
        component: () => import('/app/app/frontend/pages/products/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "Product"
        }
      },
      {
        path: ':id',
        /* internal name: '/products/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/products/[id]/',
            component: () => import('/app/app/frontend/pages/products/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "Product"
            }
          },
          {
            path: 'edit',
            name: '/products/[id]/edit',
            component: () => import('/app/app/frontend/pages/products/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "Product"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/products/new',
        component: () => import('/app/app/frontend/pages/products/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "Product"
        }
      }
    ],
  },
  {
    path: '/reset-password',
    name: '/reset-password',
    component: () => import('/app/app/frontend/pages/reset-password.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth",
      "redirectIfLoggedIn": true
    }
  },
  {
    path: '/stats',
    /* internal name: '/stats' */
    /* no component */
    children: [
      {
        path: 'custom_services',
        /* internal name: '/stats/custom_services' */
        /* no component */
        children: [
          {
            path: 'pivot_table',
            name: '/stats/custom_services/pivot_table',
            component: () => import('/app/app/frontend/pages/stats/custom_services/pivot_table.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "stats",
              "transmitDateRange": true
            }
          },
          {
            path: 'tunnel',
            name: '/stats/custom_services/tunnel',
            component: () => import('/app/app/frontend/pages/stats/custom_services/tunnel.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "stats",
              "transmitDateRange": true
            }
          },
          {
            path: 'validations',
            name: '/stats/custom_services/validations',
            component: () => import('/app/app/frontend/pages/stats/custom_services/validations.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "stats",
              "transmitDateRange": true
            }
          }
        ],
      },
      {
        path: 'distribution',
        name: '/stats/distribution',
        component: () => import('/app/app/frontend/pages/stats/distribution.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "stats"
        }
      },
      {
        path: 'landings',
        name: '/stats/landings',
        component: () => import('/app/app/frontend/pages/stats/landings.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "stats",
          "transmitDateRange": true
        }
      },
      {
        path: 'multiproduct_widgets',
        name: '/stats/multiproduct_widgets',
        component: () => import('/app/app/frontend/pages/stats/multiproduct_widgets.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "stats",
          "transmitDateRange": true
        }
      },
      {
        path: 'popins',
        name: '/stats/popins',
        component: () => import('/app/app/frontend/pages/stats/popins.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "stats",
          "transmitDateRange": true
        }
      },
      {
        path: 'reports',
        name: '/stats/reports',
        component: () => import('/app/app/frontend/pages/stats/reports.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "report"
        }
      },
      {
        path: 'widgets',
        /* internal name: '/stats/widgets' */
        /* no component */
        children: [
          {
            path: 'ab_tests',
            name: '/stats/widgets/ab_tests',
            component: () => import('/app/app/frontend/pages/stats/widgets/ab_tests.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "stats",
              "transmitDateRange": true
            }
          },
          {
            path: 'devices',
            name: '/stats/widgets/devices',
            component: () => import('/app/app/frontend/pages/stats/widgets/devices.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "stats",
              "transmitDateRange": true
            }
          },
          {
            path: 'parent_sites',
            name: '/stats/widgets/parent_sites',
            component: () => import('/app/app/frontend/pages/stats/widgets/parent_sites.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "stats",
              "transmitDateRange": true
            }
          },
          {
            path: 'pivot_table',
            name: '/stats/widgets/pivot_table',
            component: () => import('/app/app/frontend/pages/stats/widgets/pivot_table.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "stats",
              "transmitDateRange": true
            }
          },
          {
            path: 'retailers',
            name: '/stats/widgets/retailers',
            component: () => import('/app/app/frontend/pages/stats/widgets/retailers.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "stats",
              "transmitDateRange": true
            }
          },
          {
            path: 'tunnel',
            name: '/stats/widgets/tunnel',
            component: () => import('/app/app/frontend/pages/stats/widgets/tunnel.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "stats",
              "transmitDateRange": true
            }
          }
        ],
      }
    ],
  },
  {
    path: '/stores',
    /* internal name: '/stores' */
    /* no component */
    children: [
      {
        path: '',
        name: '/stores/',
        component: () => import('/app/app/frontend/pages/stores/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "Store"
        }
      },
      {
        path: ':id',
        /* internal name: '/stores/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/stores/[id]/',
            component: () => import('/app/app/frontend/pages/stores/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "Store"
            }
          },
          {
            path: 'edit',
            name: '/stores/[id]/edit',
            component: () => import('/app/app/frontend/pages/stores/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "edit",
              "subject": "Store"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/stores/new',
        component: () => import('/app/app/frontend/pages/stores/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "Store"
        }
      }
    ],
  },
  {
    path: '/users',
    /* internal name: '/users' */
    /* no component */
    children: [
      {
        path: '',
        name: '/users/',
        component: () => import('/app/app/frontend/pages/users/index.vue'),
        /* no children */
        meta: {
          "action": "manage",
          "subject": "User"
        }
      },
      {
        path: ':id',
        /* internal name: '/users/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/users/[id]/',
            component: () => import('/app/app/frontend/pages/users/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'edit',
            name: '/users/[id]/edit',
            component: () => import('/app/app/frontend/pages/users/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "edit",
              "subject": "User"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/users/new',
        component: () => import('/app/app/frontend/pages/users/new.vue'),
        /* no children */
        meta: {
          "action": "new",
          "subject": "User"
        }
      },
      {
        path: 'settings',
        name: '/users/settings',
        component: () => import('/app/app/frontend/pages/users/settings.vue'),
        /* no children */
        meta: {
          "action": "self_edit",
          "subject": "User"
        }
      }
    ],
  },
  {
    path: '/versions',
    /* internal name: '/versions' */
    /* no component */
    children: [
      {
        path: '',
        name: '/versions/',
        component: () => import('/app/app/frontend/pages/versions/index.vue'),
        /* no children */
        meta: {
          "action": "index",
          "subject": "Version"
        }
      },
      {
        path: ':id',
        /* internal name: '/versions/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/versions/[id]/',
            component: () => import('/app/app/frontend/pages/versions/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "Version"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/widgets',
    /* internal name: '/widgets' */
    /* no component */
    children: [
      {
        path: '',
        name: '/widgets/',
        component: () => import('/app/app/frontend/pages/widgets/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "Widget"
        }
      },
      {
        path: ':id',
        /* internal name: '/widgets/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/widgets/[id]/',
            component: () => import('/app/app/frontend/pages/widgets/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "show",
              "subject": "Widget"
            }
          },
          {
            path: 'edit',
            name: '/widgets/[id]/edit',
            component: () => import('/app/app/frontend/pages/widgets/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "Widget"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/widgets/new',
        component: () => import('/app/app/frontend/pages/widgets/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "Widget"
        }
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

