<script setup>
import { Toaster } from 'vue-sonner'
</script>

<template>
  <Toaster
    expand
    offset="24px"
    position="top-center"
    :toast-options="{
      duration: 5000,
      unstyled: true,
      classes: {
        default: 'toast-default',
        success: 'toast-success',
        error: 'toast-error',
        info: 'toast-info'
      }
    }"
  >
    <template #success-icon>
      <VIcon
        size="20"
        icon="bx-check"
      />
    </template>
    <template #error-icon>
      <VIcon
        size="20"
        icon="bx-x"
      />
    </template>
    <template #info-icon>
      <VIcon
        size="20"
        icon="bx-info-circle"
      />
    </template>
  </Toaster>
</template>

<style>
@media screen and (min-width: 600px) {
  /* Toaster layout to center toasts */
  [data-sonner-toaster] {
    right: 0;
    width: 100%;
    display: grid;
    justify-items: center;
  }

  /* Overwrite default layout center */
  [data-sonner-toaster][data-x-position=center] {
    left: 0;
    transform: none;
  }
}

.toast-default {
  display: flex;
  align-items: center;
  min-height: 36px;
  width: max-content;
  max-width: 600px;
  border-radius: 36px;
  padding: 8px 16px;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  background-color: rgb(var(--v-theme-secondary));
  color: rgb(var(--v-theme-on-secondary));
}

.toast-success {
  background-color: rgb(var(--v-theme-success));
  color: rgb(var(--v-theme-on-success));
}

.toast-error {
  background-color: rgb(var(--v-theme-error));
  color: rgb(var(--v-theme-on-error));
}

.toast-info {
  background-color: rgb(var(--v-theme-info));
  color: rgb(var(--v-theme-on-info));
}

[data-sonner-toaster] {
  font-family: unset;
}
</style>
