import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client/core'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'

if (process.env.NODE_ENV === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const client = new ApolloClient({
  link: ApolloLink.from([
    createUploadLink({
      uri: '/graphql'
    })
  ]),
  cache: new InMemoryCache(),
  queryDeduplication: false,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only' // Disable cache to avoid handling cache evict complexity
    }
  }
})

export default client
