import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'
import { en, fr } from 'vuetify/locale'

messages.en.$vuetify = en
messages.fr.$vuetify = fr

export default createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages
})
