import { Ability, createAliasResolver } from '@casl/ability'

const resolveAction = createAliasResolver({
  read: ['index', 'show']
})

export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth'
  }
]

export default new Ability(initialAbility, { resolveAction })
