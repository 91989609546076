/* eslint-disable import/order */
import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import ability from '@/plugins/casl/ability'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import { abilitiesPlugin } from '@casl/vue'
import '@/styles/styles.scss'
import 'unfonts.css'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

// Create vue app
const app = createApp(App)

// Create pinia store
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

// Use plugins
app.use(vuetify)
app.use(pinia)
app.use(router)
app.use(i18n)
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true
})

// Provide router and ability to pinia store
pinia.use(({ store }) => {
  store.router = markRaw(router)
  store.ability = markRaw(ability)
  store.i18n = markRaw(i18n.global)
})

// Mount vue app
app.mount('#app')
