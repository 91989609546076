import { canNavigate } from '@/plugins/casl/utils'
import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router/auto'
import { routes } from 'vue-router/auto-routes'
import { useUserStore } from '@/stores/user'

routes.push({
  name: '/',
  path: '/',
  redirect: '/dashboard'
})

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    ...setupLayouts(routes)
  ]
})

router.beforeEach(async (to, from) => {
  const userStore = useUserStore()
  await userStore.authenticate()
  const isLoggedIn = userStore.isLoggedIn

  // Check if the user can navigate to the route
  if (canNavigate(to)) {
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      return '/'
    }
  } else {
    if (isLoggedIn) {
      return { name: '/not-authorized' }
    } else {
      return { name: '/login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
    }
  }

  // Transmit query params
  const queryParamsToTransmit = ['selectedGroupId']
  if (to.meta.transmitDateRange) {
    queryParamsToTransmit.push(...['startDate', 'endDate'])
  }

  if (
    isLoggedIn &&
    from.name !== to.name &&
    queryParamsToTransmit.some(param => from.query[param] && !to.query[param])
  ) {
    const newQuery = { ...to.query }
    queryParamsToTransmit.forEach(param => {
      if (from.query[param] && !to.query[param]) {
        newQuery[param] = from.query[param]
      }
    })
    return { ...to, query: newQuery }
  }
})

// Load selected group
router.afterEach(async to => {
  const userStore = useUserStore()
  const isLoggedIn = userStore.isLoggedIn

  if (isLoggedIn) {
    if (userStore.isAdmin && to.query.selectedGroupId) {
      const selectedGroupId = parseInt(to.query.selectedGroupId)

      userStore.selectedGroupId = selectedGroupId

      // If `selectedGroupId` query param is different from the group in user store, fetch the new group
      if (selectedGroupId !== userStore.selectedGroup?.id) {
        await userStore.fetchSelectedGroup(selectedGroupId)
      }
    } else {
      // Fallback to user group
      userStore.selectedGroupId = userStore.currentUser?.group?.id
      userStore.selectedGroup = userStore.currentUser?.group
    }
  }
})

export default router
